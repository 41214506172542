<template>
	<transition name="dialog-anim">
		<div
			v-if="active && action"
			class="dialog active"
			@click.stop
		>
			<div class="dialog-heading">
				Set Action Data
			</div>
			<div class="dialog-body">
				<TextInput label="Action Name" v-model="name" />
			</div>
			<div class="dialog-actions">
				<button @click="submit">Assign</button>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TextInput } from "../inputs/index";
import { LOG_DYNAMIC_SOURCE_MANUAL } from "../../data/dialog-keys";

export default {
	components: { TextInput },
	props: {
        action: {}
	},
	data: () => ({
		name: "",
    }),
    computed: {
        ...mapGetters(["dialog"]),
        active() {
            return this.dialog.current == LOG_DYNAMIC_SOURCE_MANUAL
        }
    },
    watch: {
        action(action) {
            if (!action || !action.dynamicData)
            {
                this.name = "";
                return;
            }

            this.name = action.dynamicData.name;
        }
    },
	methods: {
        ...mapActions({
            clearDialog: "clearDialog"
        }),
		close() {
			this.$emit("close");
		},
		submit() {
            this.action.dynamicData = {
                name: this.name
            };
            this.action.isDynamicAssigned = true;
            this.clearDialog();
		},
	},
};
</script>
