<template>
	<div class="page">
		<DialogMask />

		<div v-if="current.loading">
			Loading...
		</div>

		<div v-if="current">
			<h3
				v-if="current.data && current.data.name"
				v-text="current.data.name"
			></h3>
			<h4 v-if="current.data && current.data.date">
				<DateTime :date="current.data.date" />
			</h4>
			<h4>{{ toTime(totalElapsed + timer.elapsed) }}</h4>
			<h4 v-if="autoPlay">
				<i class="fal fa-fw fa-play-circle"></i> AUTO PLAY
			</h4>

			<div class="menu-log-actions">

				<Card
					v-for="(action, i) in actions"
					:key="`action_${i}`"
					:class="['card-h elevation-1', { 'border': i == index }]"
					:name="action.dynamicData ? `${action.dynamicData.name} <small>(${actionData[i].name})</small>` : actionData[i].name"
					:icon="(actionData[i].isDynamic && !action.dynamicData) ? 'exclamation-square' : action.completed == 0 ? 'empty-set' : action.completed == 1 ? 'star' : 'check'"
					:theme="(actionData[i].isDynamic && !action.dynamicData) ? 'warning' : action.completed == 0 ? 'default' : action.completed == 1 ? 'great' : 'good'"
					:fnClick="() => actionClick(i)"
					:actions="[
						{
							fnClick: () => openDataSourceList(i),
							icon: 'link',
							show: actionData[i].dynamicSources && actionData[i].dynamicSources.length > 0
						}
					]"
					:units="[
						{
							value: toTime(
								actions[i].elapsed +
									(index == i && timer.playing
										? timer.elapsed
										: 0)
							)
						},
						...actionData[i].units.map(unit => ({
							value: `${unitData[unit.type][unit.key].prefix}${unit.value}${unitData[unit.type][unit.key].suffix}`
						}))
					]"
					/>

			</div>
		</div>

		<transition name="dialog-anim">
			<div
				v-if="dialog.current == dialogKeys.LOG_FOCUS"
				class="dialog dialog-focus-action active"
			>
				<div
					class="seg-progress"
					:style="{
						'grid-template-columns': `repeat(${actions.length}, 1fr)`,
					}"
				>
					<div
						:class="[
							'bar',
							{
								active: index == n - 1,
								completed: actions[n - 1].completed,
							},
						]"
						v-for="n in actions.length"
						:key="n"
					></div>
				</div>

				<template v-if="index < actions.length">
					<div class="title">
						{{ actionData[index].name }}
					</div>
					<div class="units">
						<span
							class="unit"
							v-for="(unit, i) in actionData[index].units || []"
							:key="i"
						>
							{{ unitData[unit.type][unit.key].prefix
							}}{{ unit.value
							}}{{ unitData[unit.type][unit.key].suffix }}
						</span>
					</div>

					<div v-if="index < actions.length - 1" class="next">
						Next:
						{{ actionData[index + 1].name }}
					</div>

					<span class="elapsed">
						<span>{{
							toTime(
								focused.elapsed +
									(timer.playing ? timer.elapsed : 0)
							)
						}}</span>
					</span>
					<div class="actions">
						<button @click="decrIndex">
							<i class="fal fa-fw fa-angle-up"></i>
						</button>
						<button @click="incrIndex">
							<i class="fal fa-fw fa-angle-down"></i>
						</button>
						<button
							v-if="playable && !timer.playing"
							@click="() => setPlaying(true)"
						>
							<i class="fal fa-fw fa-play"></i>
						</button>
						<button
							v-if="timer.playing"
							@click="() => setPlaying(false)"
						>
							<i class="fal fa-fw fa-pause"></i>
						</button>
						<button @click="incrIndexComplete">
							<i class="fal fa-fw fa-check"></i>
						</button>
					</div>
				</template>
				<template v-else>
					<div class="title">Complete!</div>
					<div class="actions">
						<button @click="decrIndex">
							<i class="fal fa-fw fa-angle-up"></i>
						</button>
						<button @click="save">
							<i class="fal fa-fw fa-save"></i>
						</button>
					</div>
				</template>
			</div>
		</transition>

		<div class="action-bar-container">
			<div class="action-bar-row">
				<div class="action-bar action-bar-v" @click.stop>
					<button @click="() => setDialog(dialogKeys.LOG_REMOVE)">
						<i class="fal fa-fw fa-trash"></i>
						<label>Remove</label>
					</button>
					<button @click="toggleAutoPlay">
						<i
							:class="[
								'fal fa-fw',
								{
									'fa-toggle-on': autoPlay,
									'fa-toggle-off': !autoPlay,
								},
							]"
						></i>
						<label>Auto-play</label>
					</button>
					<button @click="() => pushDialog(dialogKeys.LOG_FOCUS)">
						<i class="fal fa-fw fa-eye"></i>
						<label>Focus</label>
					</button>
					<button @click="save">
						<i class="fal fa-fw fa-save"></i>
						<label>Save</label>
					</button>
				</div>
			</div>
			<div class="action-bar-row">
				<div class="action-bar" @click.stop>
					<button @click="back">
						<i class="fal fa-fw fa-arrow-left"></i>
					</button>
					<button @click="decrIndex">
						<i class="fal fa-fw fa-angle-up"></i>
						<label>Prev</label>
					</button>
					<button @click="incrIndex">
						<i class="fal fa-fw fa-angle-down"></i>
						<label>Next</label>
					</button>
					<button
						v-if="playable && !timer.playing"
						@click="() => setPlaying(true)"
					>
						<i class="fal fa-fw fa-play"></i>
						<label>Play</label>
					</button>
					<button
						v-if="timer.playing"
						@click="() => setPlaying(false)"
					>
						<i class="fal fa-fw fa-pause"></i>
						<label>Pause</label>
					</button>
				</div>
				<a
					class="btn btn-action active"
					@click="incrIndexComplete"
					href="#"
				>
					<i class="fal fa-fw fa-check"></i>
					<label>Complete</label>
				</a>
			</div>
		</div>

		<DynamicManual :action="action" />
		<DynamicTrelloList :action="action" />

		<SideMenu key="log-data-sources" :dialogKey="dialogKeys.LOG_DYNAMIC_SOURCE">
			<div class="menu-heading">Select Data Source</div>
			<div v-if="actionContext">

				<Card
					v-for="(source, i) in actionContext.dynamicSources"
					:key="`data-source_${i}`"

					class="light boxed"
					:name="sourceData[source.type].name"
					:fnClick="() => openDataSource(source)"
					/>
					
			</div>
		</SideMenu>

		<ConfirmationDialog
			:dialogKey="dialogKeys.LOG_REMOVE"
			heading="Remove Log?"
			@confirm="remove"
		/>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DateTime from "../components/DateTime";
import SideMenu from "../components/menus/SideMenu";
import ConfirmationDialog from "../components/dialogs/ConfirmationDialog";

import DialogMask from "../components/DialogMask";
import {
	DynamicManual,
	DynamicTrelloList,
} from "@/components/dynamic-actions/index";
import { sourceData } from "../data/dynamic-sources";

import { toTime, useTimer } from "../helpers/time";
import {
	LOG_FOCUS,
	LOG_DYNAMIC_SOURCE,
	LOG_DYNAMIC_SOURCE_MANUAL,
	LOG_DYNAMIC_SOURCE_TRELLO,
	LOG_REMOVE,
} from "../data/dialog-keys";
import Card from "../components/Card";

export default {
	name: "Log",
	components: {
		DateTime,
		SideMenu,
		DynamicManual,
		DynamicTrelloList,
		DialogMask,
		ConfirmationDialog,
		Card
	},
	props: {
		refId: {},
	},
	data() {
		const t = this;
		return {
			action: null,
			actionData: [],

			requiresSave: false,
			playStart: null,
			autoPlay: false,
			actions: [],
			index: 0,

			focus: false,

			assignDynamic: {
				index: null,
				action: null,
				actionTask: null,
				type: null,
			},
			timer: useTimer({
				fnElapsed: (millis) => {
					t.checkAutoNext(millis);
				},
				fnPlay: () => {
					t.setRequiresSave();
				},
				fnStop: (millis) => {
					if (!t.current.focused) return;

					t.addActionElapsed(t.index, millis);
				},
			}),
		};
	},
	created() {
		this.load();
	},
	computed: {
		...mapGetters({
			dialog: "dialog",
			actionContext: "tasks/actionContext",
			current: "logs/current",
			logs: "logs/data",
			tasks: "tasks/data",
			unitData: "unitData",
		}),
		dialogKeys: () => ({
			LOG_FOCUS,
			LOG_DYNAMIC_SOURCE,
			LOG_DYNAMIC_SOURCE_MANUAL,
			LOG_DYNAMIC_SOURCE_TRELLO,
			LOG_REMOVE,
		}),
		sourceData: () => sourceData,
		focused() {
			if (this.index >= 0 && this.index < this.actions.length)
				return this.actions[this.index];

			return {
				elapsed: 0,
			};
		},
		playable() {
			return this.index < (this.actions || []).length;
		},
		totalElapsed() {
			return this.actions.reduce((p, c) => p + c.elapsed, 0);
		},
	},
	watch: {
		refId() {
			this.load();
		},
	},
	methods: {
		...mapActions({
			loadAll: "loadAll",
			update: "logs/update",
			deleteLog: "logs/deleteLog",
			success: "broadcastSuccess",
			error: "broadcastError",
			pushDialog: "pushDialog",
			setDialog: "setDialog",
			setLogContext: "logs/setLogContext",
			setActionContext: "tasks/setActionContext",
		}),

		async load() {
			await this.loadAll();
			await this.setLogContext(this.refId);
			this.actions = this.current.actions.map((action) => ({
				refId: action.refId,
				elapsed: action.elapsed,
				completed: action.completed,
				dynamicData: action.dynamicData,
			}));
			this.actionData = this.actions.map((action) => {
				return this.tasks.getActionFromRefId(action.refId);
			});
		},

		setRequiresSave() {
			this.requiresSave = true;
		},
		setActionComplete(index) {
			this.actions[index].completed = 1;
		},
		toggleActionComplete(index) {
			const action = this.actions[index];
			action.completed = action.completed > 0 ? 0 : 1;
		},

		// playing
		setPlaying(playing) {
			this.setRequiresSave();
			if (this.timer.playing) this.timer.stop();
			if (playing) this.timer.play();
		},
		setAutoPlay(autoPlay) {
			this.autoPlay = autoPlay;
		},
		toggleAutoPlay() {
			this.setAutoPlay(!this.autoPlay);
		},
		setActionElapsed(index, millis) {
			this.actions[index].elapsed = millis;
		},
		addActionElapsed(index, millis) {
			this.actions[index].elapsed += millis;
		},

		// index
		shiftIndex(index) {
			this.index = index;
		},
		incrIndex() {
			this.setRequiresSave();
			const playing = this.timer.playing;
			this.setPlaying(false);

			if (this.index >= this.actions.length) return;

			this.index++;
			if (playing && this.autoPlay) this.setPlaying(true);
		},
		decrIndex() {
			this.setRequiresSave();
			this.setPlaying(false);
			this.setAutoPlay(false);

			if (this.index <= 0) return;

			this.index--;
		},
		incrIndexComplete() {
			if (this.index < 0 || this.index >= this.actions.length) return;

			this.addActionElapsed(this.index, this.timer.elapsed);
			this.timer.resetElapsed();

			const data = this.actionData[this.index];

			if (data.isDynamic && !this.actions[this.index].dynamicData) {
				this.error(`'${data.name}' is not assigned!`);
				return;
			}

			this.setRequiresSave();
			this.setActionComplete(this.index);
			this.success(`'${data.name}' complete!`);
			this.incrIndex();
			this.setPlaying(this.autoPlay && this.index < this.actions.length);
		},

		// dynamic
		setActionDynamicData(index, data) {
			const action = this.actions[this.index];
			action.dynamicData = data;
			action.isDynamicAssigned = true;
		},

		async remove() {
			this.loading = true;
			await this.deleteLog(this.current.data.refId);
			this.loading = false;
			this.success("Deleted!");
			this.removeConfirmation = false;
			this.$router.push("/board");
		},
		async save() {
			if (this.timer.playing) this.timer.stop();
			this.loading = true;
			const res = await this.update({
				...this.current.data,
				actions: this.actions,
			});
			if (!res.statusCode == 200) {
				this.error(res.statusMessage);
				return;
			}

			this.success("Saved!");
			this.requiresSave = false;
			this.loading = false;
			this.$router.push("/board");
		},
		actionClick(i) {
			this.requiresSave = true;
			this.index = i;
			const actionTask = this.actionData[i];
			const action = this.actions[i];

			if (!actionTask.isDynamic || action.isDynamicAssigned) {
				this.toggleActionComplete(i);
			} else {
				this.action = action;
				this.setActionContext(actionTask);
				this.pushDialog(this.dialogKeys.LOG_DYNAMIC_SOURCE);
			}
		},

		openDataSourceList(i) {
			this.index = i;
			this.action = this.actions[i];
			const actionData = this.actionData[i];

			if (!actionData.dynamicSources || actionData.dynamicSources.length == 0)
				return;

			this.setActionContext(actionData);
			if (actionData.dynamicSources.length > 1)
			{
				this.pushDialog(this.dialogKeys.LOG_DYNAMIC_SOURCE);
			}
			else
			{
				this.openDataSource(actionData.dynamicSources[0]);
			}
		},
		openDataSource(source) {
			if (this.index > this.actions.length)
				return;

			this.action = this.actions[this.index];
			switch (source.type) {
				case "manual":
					this.pushDialog(this.dialogKeys.LOG_DYNAMIC_SOURCE_MANUAL);
					return;

				case "trello-list":
					this.pushDialog(this.dialogKeys.LOG_DYNAMIC_SOURCE_TRELLO);
					return;
			}
		},

		toggleFocus() {
			this.focus = !this.focus;
		},
		checkAutoNext() {
			if (!this.autoPlay || this.index >= this.actions.length) return;

			const timeUnit = this.actionData[this.index].units.find(
				(x) => x.type === "time"
			);

			if (!timeUnit) return;

			var autoNextTime =
				timeUnit.value * this.unitData["time"][timeUnit.key].factor;

			if (this.timer.elapsed > autoNextTime) {
				this.incrIndexComplete();
			}
		},
		toTime,
		async back() {
			if (this.timer.playing) this.timer.stop();
			if (this.current.requiresSave) await this.save();
			this.$router.push("/board");
		},
	},
};
</script>
