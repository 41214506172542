<template>
    <span>{{value}}</span>
</template>

<script>
import dayjs from "dayjs";

export default {
    props: {
        date: {}
    },
    computed: {
        value() {
            if (!this.date)
                return "No Date";

            return dayjs(this.date).format('ddd, DD MMM YYYY HH:mm');
        }
    }
}
</script>