<template>
	<transition name="dialog-anim">
		<div v-if="active" :class="['dialog dialog-confirmation active']" @click.stop>
            <div class="dialog-heading" v-html="heading"></div>
            <div class="dialog-actions">
                <button class="text" @click="confirm">
                    <i class="fal fa-fw fa-check"></i>
                </button>
            </div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	props: {
        dialogKey: String,
        heading: String
	},
	computed: {
		...mapGetters({
			dialog: "dialog",
		}),
		active() {
			return this.dialog.current == this.dialogKey;
		},
	},
	methods: {
		close() {
			this.$emit("close");
        },
        confirm() {
            this.$emit("confirm");
        }
	},
};
</script>
