<template>
	<transition name="dialog-anim">
		<div v-if="active && action" class="dialog active" @click.stop>
			<TrelloSearch
				:canOpen="['board', 'list']"
				:canSelect="['card']"
				v-model="params"
				@input="submit"
				:root="config"
			/>
		</div>
	</transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import { TextInput } from "../inputs/index";
import { LOG_DYNAMIC_SOURCE_TRELLO } from "../../data/dialog-keys";

import TrelloSearch from "../search/TrelloDynamicSearch";

export default {
	components: {
		// TextInput,
		TrelloSearch,
	},
	props: {
		action: {},
	},
	data: () => ({
		params: {},
		config: {
			obj: "member",
			name: "All"
		},
	}),
	computed: {
		...mapGetters({
			dialog: "dialog",
			tasks: "tasks/data",
		}),
		active() {
			return this.dialog.current == LOG_DYNAMIC_SOURCE_TRELLO;
		},
	},
	watch: {
		action(action) {
			if (!action) {
				this.name = "";
				return;
			}

			let actionData = this.tasks.getActionFromRefId(action.refId);
			if (actionData) {
				let source = actionData.dynamicSources.find(
					(x) => x.type == "trello-list"
				);
				if (source) {
					this.config.obj = source.obj;
					this.config.name = source.name;
					if (source.id) this.config.id = source.id;
					else delete this.config.id;
				}
			}

			if (!action.dynamicData) {
				this.name = "";
				return;
			}

			this.name = action.dynamicData.name;
		},
	},
	methods: {
		...mapActions({
			clearDialog: "clearDialog",
		}),
		close() {
			this.$emit("close");
		},
		submit() {
			this.action.dynamicData = {
				name: this.params.name,
			};
			this.action.isDynamicAssigned = true;
			this.clearDialog();
		},
	},
};
</script>
